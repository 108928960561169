import React from 'react';
import { arrayOf, number, objectOf, shape, string } from 'prop-types';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useFinancialServicesNew from './useFinancialServicesNew';
import ActionButtons from './ActionButtons';
import ServiceGroup from './ServiceGroup';
import { ServicesGrid, ActionButtonsWrapper } from './FinancialServicesSeparateSale.styles';
import ProductCard from './ProductCard';
var FinancialServicesSeparateSale = function FinancialServicesSeparateSale(props) {
  var _useFinancialServices = useFinancialServicesNew(props),
    actionButtonProps = _useFinancialServices.actionButtonProps,
    services = _useFinancialServices.services,
    productInfo = _useFinancialServices.productInfo;
  return React.createElement(Grid, {
    "data-testid": "services-container-desktop"
  }, React.createElement(ProductCard, {
    productInfo: productInfo
  }), React.createElement(ServicesGrid, {
    "data-testid": "services-grid",
    nCards: services.length
  }, services.map(function (service) {
    return React.createElement(ServiceGroup, {
      key: "service-group-".concat(service.key),
      service: service
    });
  })), React.createElement(ActionButtonsWrapper, null, React.createElement(ActionButtons, actionButtonProps)));
};
FinancialServicesSeparateSale.defaultProps = {
  data: {
    product: {
      financialServices: [],
      installment: {}
    },
    serviceContent: {
      financialServicesContent: [],
      footerContent: []
    }
  },
  "static": {
    extraInfo: {},
    overrideDeclineMessages: {}
  }
};
process.env.NODE_ENV !== "production" ? FinancialServicesSeparateSale.propTypes = {
  data: shape({
    product: shape({
      financialServices: arrayOf(shape({
        description: string,
        id: string
      })),
      installment: shape({
        quantity: number
      })
    }),
    serviceContent: shape({
      financialServicesContent: arrayOf(shape({
        title: string
      })),
      footerContent: arrayOf(shape({
        title: string
      }))
    })
  }),
  "static": shape({
    extraInfo: shape({
      label: string,
      labelBold: string,
      title: string
    }),
    overrideDeclineMessages: objectOf(shape({
      description: string
    }))
  })
} : void 0;
FinancialServicesSeparateSale.ssr = true;
FinancialServicesSeparateSale.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  serviceContent: {
    query: 'ServiceContentQuery'
  }
};
export default withLayoutProps(FinancialServicesSeparateSale);