import useAdaptData from './useAdaptData';
import getAuthorizationTermText from './getAuthorizationTermText';
import useLegalInfo from './useLegalInfo';
import getServiceInfos from './getServiceInfos';
import useActionButtons from './useActionButtons';
import useSelectedServices from './useSelectedServices';
import useServicesSelectedOffer from './useServicesSelectedOffer';
var useFinancialServicesNew = function useFinancialServicesNew(props) {
  var _services$;
  var _useAdaptData = useAdaptData(props),
    cart = _useAdaptData.cart,
    channel = _useAdaptData.channel,
    footerContent = _useAdaptData.footerContent,
    installments = _useAdaptData.installments,
    location = _useAdaptData.location,
    partner = _useAdaptData.partner,
    product = _useAdaptData.product,
    rawProduct = _useAdaptData.rawProduct,
    services = _useAdaptData.services,
    authorizationTermText = _useAdaptData.authorizationTermText;
  var _useSelectedServices = useSelectedServices((_services$ = services[0]) == null ? void 0 : _services$.id),
    selectedServicesIds = _useSelectedServices.selectedServicesIds;
  var _useServicesSelectedO = useServicesSelectedOffer(services),
    servicesSelectedOfferId = _useServicesSelectedO.servicesSelectedOfferId,
    onServicesSelectedOfferChange = _useServicesSelectedO.onServicesSelectedOfferChange;
  var adaptedServices = services == null ? void 0 : services.map(function (service) {
    return getServiceInfos({
      installments: installments,
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      service: service,
      servicesSelectedOfferId: servicesSelectedOfferId
    });
  });
  var handleAcquireServices = useActionButtons({
    cart: cart,
    channel: channel,
    location: location,
    partner: partner,
    product: product,
    rawProduct: rawProduct,
    selectedServicesIds: selectedServicesIds,
    servicesSelectedOfferId: servicesSelectedOfferId
  });
  var actionButtonProps = {
    authorizationTermText: getAuthorizationTermText({
      authorizationTermTextRaw: authorizationTermText,
      selectedServicesIds: selectedServicesIds,
      services: services,
      servicesSelectedOfferId: servicesSelectedOfferId
    }),
    handleAcquireServices: handleAcquireServices
  };
  var productInfo = {
    alt: product == null ? void 0 : product.title,
    src: product == null ? void 0 : product.image,
    title: product.title
  };
  var legalInfos = useLegalInfo({
    footerContent: footerContent
  });
  return {
    actionButtonProps: actionButtonProps,
    legalInfos: legalInfos,
    productInfo: productInfo,
    services: adaptedServices,
    servicesSelectedOfferId: servicesSelectedOfferId
  };
};
export default useFinancialServicesNew;